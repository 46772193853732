@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

*, body, button, input, select {
    font-family: "Poppins", sans-serif;
}
